import React from "react";
import data from "../../data/data.json";
import { Grid, Radio, Group, Button, Title } from '@mantine/core'

const PropertyType = ({ nextStep, form }) => {
    // for continue event listener
    const isFormValid = event => {
        return (
            form.values.propertyType.length > 0
        );
    };

    return (
        <Grid>
            <Grid.Col>
                <Title order={3}>Property Type</Title>
                <Radio.Group
                    mt="sm"
                    name="propertyType"
                    withAsterisk
                    {...form.getInputProps('propertyType')}
                >
                    <Group>
                        {data.property_types.map((property_type) => {
                            return (<Radio key={property_type.id} value={property_type.value} label={property_type.label} />)
                        })}
                    </Group>
                </Radio.Group>
                <Group position="right" mt="xl">
                    <Button onClick={nextStep} disabled={!isFormValid()}>Next</Button>
                </Group>
            </Grid.Col>
        </Grid>

    );
};

export default PropertyType;
