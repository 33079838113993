import React, { useState } from "react";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import Error from "./Error";
import Loading from "./Loading";
import RequireSignIn from "./RequireSignIn";
import { useForm } from '@mantine/form';
import { GET_PLACES_QUERY, LIMIT, OFFSET } from "../pages/Places";
import PropertyType from "./places-multi/PropertyType";
import PropertyLocation from "./places-multi/PropertyLocation";
import Basic from "./places-multi/Basic";
import Attributes from "./places-multi/Attributes";
import Features from "./places-multi/Features";
import Pricing from "./places-multi/Pricing";
import Images from "./places-multi/Images";
import Confirmation from "./places-multi/Confirmation";
import { useHistory } from "react-router-dom";
import { Container, Paper } from '@mantine/core';

const CREATE_PLACE_MUTATION = gql`
mutation CreatePlace(
  $name: String!
  $description: String!
  $propertyType: String!
  $country: String!
  $location: String!
  $pricePerNight: Int!
  $bedrooms: Int!
  $bathrooms: Int!
  $beds: Int!
  $maxGuests: Int!
  $selectedFiles: [Upload]!

  $aircondition: Boolean
  $bbqGrill: Boolean
  $firePit: Boolean
  $fireplace: Boolean
  $hotTub: Boolean
  $kitchen: Boolean
  $mediaRoom: Boolean
  $parking: Boolean
  $patio: Boolean
  $petFriendly: Boolean
  $pool: Boolean
  $poolTable: Boolean
  $tv: Boolean
  $washer: Boolean
  $wifi: Boolean

) {
  createPlace(
    name: $name
    description: $description
    country: $country
    location: $location
    bedrooms: $bedrooms
    bathrooms: $bathrooms
    beds: $beds
    maxGuests: $maxGuests
    pricePerNight: $pricePerNight
    propertyType: $propertyType
    selected_files: $selectedFiles

    aircondition: $aircondition
    bbqGrill: $bbqGrill
    firePit: $firePit
    fireplace: $fireplace
    hotTub: $hotTub
    kitchen: $kitchen
    mediaRoom: $mediaRoom
    parking: $parking
    patio: $patio
    petFriendly: $petFriendly
    pool: $pool
    poolTable: $poolTable
    tv: $tv
    washer: $washer
    wifi: $wifi
  ) {
    id
  }
}
`;

const CreatePlace = () => {
  const history = useHistory();
  const [active, setActive] = useState(1);
  const [country] = useState(localStorage.getItem("country"))

  console.log(country);
  // const [step, setStep] = React.useState(1);

  const form = useForm({
    initialValues: {
      name: "", propertyType: "", description: "", bedrooms: 0,
      bathrooms: 0, beds: 0, maxGuests: 0, pricePerNight: 0,
      country: country, location: "", selectedFiles: null, petFriendly: false, pool: false,
      wifi: false, hotTub: false, patio: false, bbqGrill: false,
      firePit: false, poolTable: false, fireplace: false, mediaRoom: false,
      tv: false, kitchen: false, washer: false, parking: false,
      aircondition: false
    },
  })

  const nextStep = () =>
    setActive((current) => {
      if (form.validate().hasErrors) {
        return current;
      }
      return current < 9 ? current + 1 : current;
    });

  const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));

  const handleCompleted = () => {
    history.push("/");
  };

  const handleUpdate = (cache, { data: { createPlace } }) => {
    const data = cache.readQuery({
      query: GET_PLACES_QUERY,
      variables: { limit: LIMIT, offset: OFFSET },
    });
    data.places.unshift(createPlace);
    cache.writeQuery({
      query: GET_PLACES_QUERY,
      variables: { limit: LIMIT, offset: OFFSET },
      data,
    });
  };
  const onError = (error) => {
    console.log("Error creating place", error)
  };

  return (
    <Container size="sm" sx={{ mb: 4 }}>
      <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
        <RequireSignIn>
              <Mutation
                mutation={CREATE_PLACE_MUTATION}
                variables={{
                  name: form.values.name,
                  propertyType: form.values.propertyType,
                  description: form.values.description,
                  country: country,
                  location: form.values.location,
                  bedrooms: parseInt(form.values.bedrooms),
                  bathrooms: parseInt(form.values.bathrooms),
                  beds: parseInt(form.values.beds),
                  maxGuests: parseInt(form.values.maxGuests),
                  pricePerNight: parseInt(form.values.pricePerNight),
                  selectedFiles: form.values.selectedFiles,
                  petFriendly: form.values.petFriendly,
                  pool: form.values.pool,
                  wifi: form.values.wifi,
                  hotTub: form.values.hotTub,
                  patio: form.values.patio,
                  bbqGrill: form.values.bbqGrill,
                  firePit: form.values.firePit,
                  poolTable: form.values.poolTable,
                  fireplace: form.values.fireplace,
                  mediaRoom: form.values.mediaRoom,
                  tv: form.values.tv,
                  kitchen: form.values.kitchen,
                  washer: form.values.washer,
                  parking: form.values.parking,
                  aircondition: form.values.aircondition

                }}
                onCompleted={handleCompleted}
                update={handleUpdate}
                onError={onError}
              >
                {(createPlace, { loading, error }) => {
                  if (loading) return <Loading />;
                  if (error) return <Error />;
                  switch (active) {
                    case 1:
                      return (
                        <PropertyType
                          form={form}
                          nextStep={nextStep}
                        />
                      );
                    /* falls through */
                    case 7:
                      return (
                        <PropertyLocation
                          form={form}
                          prevStep={prevStep}
                          nextStep={nextStep}
                        />
                      );
                    /* falls through */
                    case 3:
                      return (
                        <Basic
                          form={form}
                          prevStep={prevStep}
                          nextStep={nextStep}
                        />
                      );
                    /* falls through */
                    case 4:
                      return (
                        <Attributes
                          form={form}
                          prevStep={prevStep}
                          nextStep={nextStep}
                        />
                      );
                    /* falls through */
                    case 5:
                      return (
                        <Features
                          form={form}
                          prevStep={prevStep}
                          nextStep={nextStep}
                        />
                      );
                    /* falls through */
                    case 6:
                      return (
                        <Pricing
                          form={form}
                          prevStep={prevStep}
                          nextStep={nextStep} />
                      );
                    /* falls through */
                    case 2:
                      return (
                        <Images
                          form={form}
                          prevStep={prevStep}
                          nextStep={nextStep}
                        />
                      );
                    /* falls through */
                    case 8:
                      return (
                        <Confirmation
                          form={form}
                          prevStep={prevStep}
                          nextStep={nextStep}
                        />
                      );
                    /* falls through */
                    case 9:
                      createPlace();
                    /* falls through */
                    default:
                      return <div>Step not found</div>;
                  }
                }}
              </Mutation>
        </RequireSignIn>
      </Paper>
    </Container>
  );
};

export default CreatePlace;