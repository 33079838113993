import React from "react";
import gql from "graphql-tag";
import PropTypes from "prop-types";
import { Mutation } from "react-apollo";
import { Link } from "react-router-dom";
import { formatMonthDD, totalNights, formatCurrency } from "../lib/helpers";
import { createStyles, Image, Button, Text, Badge, Card, Group, Grid, rem, Stack } from '@mantine/core';

const CANCEL_BOOKING_MUTATION = gql`
  mutation CancelBooking($bookingId: ID!) {
    cancelBooking(bookingId: $bookingId) {
      id
      state
    }
  }
`;

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
  },

  label: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 700,
    lineHeight: 1,
  },

  lead: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 600,
    fontSize: rem(24),
    lineHeight: 1,
  },

  inner: {
    display: 'flex',

    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'column',
    },
  },

  body: {
    padding: theme.spacing.sm
  },

}));

const Booking = ({ booking }) => {
  const { classes } = useStyles();

  const isReserved = () => {
    return booking.state === "reserved";
  };

  return (
    <Card withBorder radius="md" className={classes.card}>
      <div className={classes.body}>
        <Grid >
          <Grid.Col md={6} lg={4}>
            <Link to={`/places/${booking.place.slug}`}>
              <Image width={240} height={240} mx="sm" radius="md" src={booking.place.photoUrls[0]} alt="Random image" />
            </Link>
          </Grid.Col>
          <Grid.Col md={6} lg={4}>
            <div>
              <Text fz="xl" className={classes.label}>
                <Link to={`/places/${booking.place.slug}`}>
                  {booking.place.name}
                </Link>
              </Text>
              <div>
                <Text className={classes.lead} mt={30}>
                  {formatCurrency(booking.totalPrice)}
                </Text>
                <Text fz="sm" color="dimmed">
                  {totalNights(booking.startDate, booking.endDate)} nights
                </Text>
                <Text fz="sm" color="dimmed">
                  {booking.adults} {booking.adults > 1 ? "Adults" : "Adult"} {booking.children > 0 ? ", Children " + booking.children : ""}
                  {booking.infants > 0 ? ", Infants " + booking.infants : ""}
                  {booking.pets > 0 ? ", Pets " + booking.pets : ""}
                </Text>
              </div>
              <Group mt="lg">
                <div>
                  <Text className={classes.label}>Arriving</Text>
                  <Text size="xl" color="blue">
                    {formatMonthDD(booking.startDate)}
                  </Text>
                </div>
                <div>
                  <Text className={classes.label}>Departing</Text>
                  <Text size="xl" color="blue">
                    {formatMonthDD(booking.endDate)}
                  </Text>
                </div>
              </Group>
            </div>
          </Grid.Col>
          <Grid.Col md={6} lg={4}>
            <Stack align="center" spacing="xs">
              <Badge size="xl" color={booking.state === "reserved" ? "orange" : "red"}>{booking.state}</Badge>
              {isReserved() && (
                <Mutation
                  mutation={CANCEL_BOOKING_MUTATION}
                  variables={{ bookingId: booking.id }}>
                  {cancel => <Button color="red" onClick={cancel}>Cancel</Button>}
                </Mutation>
              )}
            </Stack>
          </Grid.Col>
        </Grid>
      </div>
    </Card >
  )

}

// class Booking extends Component {
//   static propTypes = {
//     booking: PropTypes.object.isRequired
//   };

//   isReserved = () => {
//     return this.props.booking.state === "reserved";
//   };

//   render() {
//     const { booking } = this.props;

//     return (
//       <>
//        hello
//       </>
//       // <Container size="xl">

//       //   <div className="body">
//       //     <div className="columns">
//       //       <div className="place">
//       //         <Link to={`/places/${booking.place.slug}`}>
//       //           <img
//       //             src={booking.place.photoUrls[0]}
//       //             alt={booking.place.name}
//       //           />
//       //           <span className="name">{booking.place.name}</span>
//       //         </Link>
//       //       </div>
//       //       <div className="dates">
//       //         <dl>
//       //           <dt>Arriving</dt>
//       //           <dd>{formatMonthDD(booking.startDate)}</dd>
//       //           <dt>Departing</dt>
//       //           <dd>{formatMonthDD(booking.endDate)}</dd>
//       //         </dl>
//       //       </div>
//       //       <div className="status">
//       //         <span className={"state " + booking.state}>{booking.state}</span>
//       //         {this.isReserved() && (
//       //           <Mutation
//       //             mutation={CANCEL_BOOKING_MUTATION}
//       //             variables={{ bookingId: booking.id }}>
//       //             {cancel => <button onClick={cancel}>Cancel</button>}
//       //           </Mutation>
//       //         )}
//       //       </div>
//       //     </div>
//       //   </div>
//       //   <div className="footer">
//       //     {totalNights(booking.startDate, booking.endDate)} nights &bull;{" "}
//       //     {formatCurrency(booking.totalPrice)}
//       //   </div>
//       // </Container>
//     );
//   }
// }

Booking.propTypes = {
  booking: PropTypes.object.isRequired
};

export default Booking;
