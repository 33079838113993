import React from "react";
import PropTypes from "prop-types";

const JobCard = ({ job }) => (
	<div className="container">
		<div className="row">
			<div className="col-sm-12 offset-sm-0">
				<div className="job-card">
					<div className="job-card__content">
						<div className="job-card__content-top">
							<div className="job-card_info">
								<a href="#!" className="float-right">
									<i className="fa fa-heart-o"></i>
								</a>
								<a href="#!" className="job-card_company">
									<h4 className="mb-1 d-inline-block">{job.companyName}</h4>
								</a>
								<p className="text-muted mb-0">{job.jobLocation}</p>
							</div>
						</div>
						<div className="job-card_content-mid">
							<h4>{job.jobTitle}</h4>
							<p className="mb-0"></p>
							<div className="job-card_job-type">
								<span className="job-label">{job.jobType}</span>
								<span className="job-label">{job.jobSource}</span>
								<span className="job-label">{job.jobCategory}</span>
							</div>
						</div>
					</div>
					<div className="job-card__footer">
						<p className="mb-1 mt-1 text-muted">Posted: 2 days ago</p>
						<a href={job.jobUrl}>
							<button className="btn btn-primary">
								Apply
								</button>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
);

JobCard.propTypes = {
	job: PropTypes.object.isRequired
};

export default JobCard;
