import React from "react";
import data from "../../data/data.json";
import { Grid, Checkbox, Group, Button, Title } from '@mantine/core'

const Attributes = ({ prevStep, nextStep, form }) => {

 return (
    <Grid>
      <Grid.Col>
        <Title order={3}>Property Type</Title>
        <Group>
          {data.features.map((feature) => {
            return (
            <Checkbox
                key={feature.id} 
                label={feature.label} 
                name={feature.name} 
                value={feature.value}
                {...form.getInputProps(feature.name)} />           
            )
          })}
        </Group>
        <Group position="right" mt="xl">
          <Button onClick={prevStep} >Previous</Button>
          <Button onClick={nextStep} >Next</Button>
        </Group>
      </Grid.Col>
    </Grid>
  );
};

export default Attributes;
