import React from "react";

const Images = ({ prevStep, nextStep, handleFileChange, values }) => {
  const Continue = (e) => {
    e.preventDefault();
    nextStep();
  };

  const Previous = (e) => {
    e.preventDefault();
    prevStep();
  };

  return (
    <div
      className="card card-body bg-white border-light col-md-6 mb-6"
    >
      <h2 className="h5 mb-4">General information - Dropzone?</h2>    

      <div className="row">
        <div className="col-sm-6 mb-3">
          <div className="form-group">
            <input
              type="file"
              className="form-control"
              name="selectedFiles"
              id="selectedFiles"
              multiple
              onChange={handleFileChange}
            />
          </div>
        </div>
      </div>

      <div className="mt-3">
        <button
          type="submit"
          className="btn btn-primary mr-2"
          onClick={Previous}
        >
          Previous
        </button>

        <button type="submit" className="btn btn-primary" onClick={Continue}>
          Next
        </button>
      </div>
    </div>
  );
};

export default Images;
