import React, { Component } from "react";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import { Link } from "react-router-dom";
import Error from "../components/Error";
import Loading from "../components/Loading";
import { GET_CURRENT_USER_QUERY } from "../components/CurrentUser";

import {
  TextInput,
  PasswordInput,
  Paper,
  Title,
  Text,
  Container,
  Button,
} from '@mantine/core';

const SIGNUP_MUTATION = gql`
  mutation SignUp(
    $first_name: String!
    $last_name: String!
    $email: String!
    $password: String!
  ) {
    signup(
      firstName: $first_name
      lastName: $last_name
      email: $email
      password: $password
    ) {
      emailToken     
    }
  }
`;

class Signup extends Component {
  state = {
    first_name: "",
    last_name: "",
    email: "",
    password: ""
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  isFormValid = (event) => {
    return (
      this.state.first_name.length > 0 &&
      this.state.last_name.length > 0 &&
      this.state.email.length > 0 &&
      this.state.password.length > 0
    );
  };

  handleCompleted = (data) => {
    this.props.history.push(`/confirm-account/${data.signup.emailToken}`);
  };

  handleUpdate = (cache, { data }) => {
    cache.writeQuery({
      query: GET_CURRENT_USER_QUERY,
      data: { me: data.signup.user },
    });
  };

  render() {
    return (
      <Mutation
        mutation={SIGNUP_MUTATION}
        variables={this.state}
        onCompleted={this.handleCompleted}
        update={this.handleUpdate}
      >
        {(signup, { loading, error }) => {
          if (loading) return <Loading />;
          return (
            <form
              className="signup"
              onSubmit={(e) => {
                e.preventDefault();
                signup();
              }}
            >
              <Container size={420} my={40}>
                <Title
                  align="center"
                  sx={(theme) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 900 })}
                >
                  Hello there!
                </Title>
                <Text color="dimmed" size="sm" align="center" mt={5}>
                  Already an account yet?{' '}
                  <Link to="/sign-in" className="anchor">Login</Link>
                </Text>
                <Error error={error} />
                <Paper withBorder shadow="md" p={30} mt={30} radius="md">
                  <TextInput
                    required
                    label="First Name"
                    placeholder="John"
                    name="first_name"
                    value={this.state.first_name}
                    onChange={this.handleChange}
                    radius="md"
                  />
                   <TextInput
                    required
                    label="Last Name"
                    placeholder="Doe"
                    name="last_name"
                    value={this.state.last_name}
                    onChange={this.handleChange}
                    radius="md"
                  />
                    <TextInput
                    required
                    label="Email Address"
                    placeholder="hello@example.com"
                    name="email"
                    value={this.state.email}
                    error={error ? true : false}
                    onChange={this.handleChange}
                    radius="md"
                  />
                  <PasswordInput
                    required
                    label="Password"
                    placeholder="Your password"
                    name="password"
                    value={this.state.password}
                    onChange={this.handleChange}
                    radius="md"
                  />
                  <Button fullWidth mt="xl" type="submit" disabled={loading || !this.isFormValid()}>
                    Create Account
                  </Button>
                </Paper>
              </Container>
            </form>
          );
        }}
      </Mutation>
    );
  }
}

export default Signup;
