import React from "react";
import { Grid, Text, Checkbox, Button, Title,  Group } from '@mantine/core';


const Confirmation = ({ prevStep, nextStep, form }) => {

  return (
    <Grid>
      <Grid.Col>
        <Title order={3}>Confirm Terms/Conditions</Title>
        <Text>
          We and selected third parties collect personal information as specified in the privacy policy and use cookies or similar technologies
          for technical purposes and, with your consent, for other purposes as specified in the cookie policy. Denying consent may make related features unavailable.
          Use the “Accept” button to consent. Use the “Reject” button or close this notice to continue without accepting.
        </Text>
        <Checkbox
          label="Confirm Terms"
          name="terms"
          {...form.getInputProps("terms")} />
      </Grid.Col>
      <Group position="right" mt="xl">
        <Button variant="contained" onClick={prevStep} >Previous</Button>
        <Button onClick={nextStep} >Next</Button>
      </Group>
    </Grid>
  );
};

export default Confirmation;
