import React from "react";
import { Button, NumberInput, Grid, Group, Title } from '@mantine/core';

const Attributes = ({ prevStep, nextStep, form }) => {

  const isFormValid = event => {
    return (
      form.values.bedrooms > 0 &&
      form.values.bathrooms > 0 &&
      form.values.beds > 0 &&
      form.values.maxGuests > 0
    );
  };

  return (
    <>
      <Grid>
        <Grid.Col>
          <Title order={3}>Property Type</Title>
          <NumberInput
            size="sm"
            mt="sm"
            name="bedrooms"
            label="Bedrooms"
            placeholder="0"
            max={10}
            {...form.getInputProps('bedrooms')}
          />
          <NumberInput
            size="sm"
            mt="sm"
            name="bathrooms"
            label="Bathrooms"
            placeholder="0"
            max={10}
            {...form.getInputProps('bathrooms')}
          />
          <NumberInput
            size="sm"
            mt="sm"
            name="beds"
            label="Beds"
            placeholder="0"
            max={10}
            {...form.getInputProps('beds')}
          />
          <NumberInput
            size="sm"
            mt="sm"
            name="maxGuests"
            label="Max Guests"
            placeholder="0"
            max={10}
            {...form.getInputProps('maxGuests')}
          />
        </Grid.Col>
      </Grid>

      <Group position="right" mt="xl">
        <Button variant="contained" onClick={prevStep} >Previous</Button>
        <Button onClick={nextStep} disabled={!isFormValid()}>Next</Button>
      </Group>
    </>
  );
};

export default Attributes;
