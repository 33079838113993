import React, { Component } from "react";
import PropTypes from "prop-types";
import { formatCurrency, totalNights } from "../lib/helpers";
import { Center } from "@mantine/core";

class BookingTotals extends Component {
  static propTypes = {
    startDate: PropTypes.instanceOf(Date).isRequired,
    endDate: PropTypes.instanceOf(Date).isRequired,
    pricePerNight: PropTypes.number.isRequired
  };

  render() {
    const { startDate, endDate, pricePerNight } = this.props;

    const nights = totalNights(startDate, endDate);
    const price = nights * parseFloat(pricePerNight);

    return (
      <Center sx={{marginBottom: "10px"}}>
        {nights} nights &bull; {formatCurrency(price)}
      </Center>
    );
  }
}

export default BookingTotals;
