import React from "react";
import { Link } from "react-router-dom";
import { formatCurrency } from "../lib/helpers";
import {
  Card,
  Image,
  Text,
  Group,
  Badge,
  createStyles,
  rem,
} from '@mantine/core';

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
  },

  section: {
    borderBottom: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
      }`,
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    paddingBottom: theme.spacing.md,
  },

  like: {
    color: theme.colors.red[6],
  },

  label: {
    textTransform: 'uppercase',
    fontSize: theme.fontSizes.xs,
    fontWeight: 700,
  },
}));

export function PlaceCard({ place }) {
  const { classes, theme } = useStyles();

  const features = place.features.slice(0, 5).map((badge) => (
    <Badge
      size="xs"
      color={theme.colorScheme === 'dark' ? 'dark' : 'blue'}
      key={badge}
    >
      {badge}
    </Badge>
  ));

  return (
    <Card withBorder radius="md" p="xs" className={classes.card}>
      <Card.Section>
        <Link to={`/places/${place.slug}`}>
          <Image src={place.photoUrls[0]} alt={place.name} height={180} />
        </Link>
      </Card.Section>

      <Card.Section className={classes.section} mt="xs">
        <Group position="apart">
          <Text fz="lg" fw={500} truncate>
            {
              (place.locationDetails) ?
                <span>{place.locationDetails.placeName}, {place.locationDetails.stateName}</span>
                :
                <span className="location">{place.location} </span>
            }
          </Text>
        </Group>
        <Text fz="xs" mt="xxs">
          Bedrooms {place.bedrooms} | Sleeps {place.maxGuests} | Bathrooms {place.bathrooms}
        </Text>
      </Card.Section>

      <Card.Section className={classes.section}>
        <Text mt="xxs" className={classes.label} c="dimmed">
          Perfect for you, if you enjoy
        </Text>
        <Group spacing={2} mt={4}>
          {features}
        </Group>
      </Card.Section>

      <Group mt="xs" position="apart">
        <Badge size="xs" color="green">{place.propertyType}</Badge>
        <Text size="md">
        <Text span fw={700}>{formatCurrency(place.pricePerNight)}</Text>/night
        </Text>
      </Group>
    </Card>
  );
}

export default PlaceCard;