import React, { useState } from "react";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import Error from "../components/Error";
import Loading from "../components/Loading";
import PlaceList from "../components/PlaceList";
import SearchCriteria from "../components/custom-inputs/PlaceSearchCriteria";
import { Container, Grid, Center, Button } from '@mantine/core';

const SEARCH_PLACES_QUERY = gql`
  query SearchPlaces($country: String, $limit: Int!, $offset: Int, $filter: PlaceFilter!) {
    places(country: $country, limit: $limit, offset: $offset, filter: $filter) {
      id
      slug
      name
      propertyType
      features
      location
      locationDetails {
        placeName
        stateName
      }
      description
      pricePerNight
      photoUrls
      maxGuests
      bedrooms
      bathrooms
      beds
    }
  }
`;

const LIMIT = 18;

const Search = () => {
  const [hasMorePlaces, setHasMorePlaces] = useState(true);
  const [criteria, setCritera] = useState({})

  const handleCriteriaChange = (criteria) => {
    setCritera(criteria)
  }
  return (
    <Container fluid>
      <Grid gutter="md">
        <Grid.Col xs={2}>
          <SearchCriteria
            criteria={criteria}
            onChange={handleCriteriaChange}
          />
        </Grid.Col>

        <Grid.Col xs={10}>
          <Query
            query={SEARCH_PLACES_QUERY}
            variables={{
              country: localStorage.getItem("country") , 
              limit: LIMIT, offset: 0,
              filter: criteria
            }}>
            {({ data, loading, error, fetchMore }) => {
              if (loading) return <Loading />;
              if (error) return <Error error={error} />;
              return (
                <>
                  <PlaceList places={data.places} />
                  <Center>
                    <Button sx={{ marginTop: "20px" }}
                      variant="default"
                      disabled={!hasMorePlaces}
                      onClick={() =>
                        fetchMore({
                          variables: {
                            offset: data.places.length
                          },
                          updateQuery: (previousResult, { fetchMoreResult }) => {
                            if (!fetchMoreResult) return previousResult;

                            if (fetchMoreResult.places.length < LIMIT) {
                              setHasMorePlaces(false);
                            }

                            return {
                              places: [
                                ...previousResult.places,
                                ...fetchMoreResult.places
                              ]
                            };
                          }
                        })
                      }>
                      See More
                    </Button>
                  </Center>
                </>
              );
            }}
          </Query>
        </Grid.Col>
      </Grid>
    </Container>
  )
}

export default Search;
