const Theme = (theme) => {
    return (
        {
            spacing: { xxs: '0.5rem', xs: '1rem', sm: '1.2rem', md: '1.8rem', lg: '2.2rem', xl: '2.8rem' },
            fontFamily: ['Source Sans Pro', 'Roboto', 'Montserrat'],
            colors: {
                'brand': ['#faebed', '#efc3ca', '#e49ba7', '#d97384', '#ce4a61', '#b53148', '#8c2638', '#641b28', '#3c1018', '#140508'],
                'ocean-blue': ['#7AD1DD', '#5FCCDB', '#44CADC', '#2AC9DE', '#1AC2D9', '#11B7CD', '#09ADC3', '#0E99AC', '#128797', '#147885'],
                'bright-pink': ['#F0BBDD', '#ED9BCF', '#EC7CC3', '#ED5DB8', '#F13EAF', '#F71FA7', '#FF00A1', '#E00890', '#C50E82', '#AD1374'],
            },
            primaryColor: 'brand',
            Container: {
                defaultProps: {
                    sizes: {
                        xs: 540,
                        sm: 720,
                        md: 960,
                        lg: 1140,
                        xl: 1600,
                    },
                },
            },
        }
    )
}

export default Theme;