import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import HeaderMenu from "./components/Header";
import Footer from "./components/Footer";
import Houses from "./pages/Houses";
import Jobs from "./pages/Jobs";
import SearchPlace from "./pages/SearchPlace";
import Places from "./pages/Places";
import Place from "./pages/Place";
import MyBookings from "./pages/MyBookings";
import Signup from "./pages/Signup";
import Signin from "./pages/Signin";
import NotFound from "./pages/NotFound";
import Doctor from "./pages/Doctor";
import CreatePlace from "./components/CreatePlace";
import CreateProfile from "./components/CreateProfile";
import ConfirmAccount from "./pages/ConfirmAccount";

import { MantineProvider } from '@mantine/core';
import "./App.css";
import Theme from './Theme';
import CreateReview from "./components/CreateReview";

const App = () => {

  return (
    <BrowserRouter>
      <MantineProvider theme={Theme()} withNormalizeCSS withGlobalStyles>
        <div id="page-container">
          <HeaderMenu />
          <main>
          <Switch>
            <Route path="/" exact component={Places} />
            <Route path="/jobs" exact component={Jobs} />
            <Route path="/doctor" exact component={Doctor} />
            <Route path="/houses" exact component={Houses} />
            <Route path="/places/search" exact component={SearchPlace} />
            <Route path="/places" exact component={Places} />
            <Route path="/places/:slug" component={Place} />
            <Route path="/create-place" component={CreatePlace} />
            <Route path="/create-review" component={CreateReview} />
            <Route path="/bookings" component={MyBookings} />
            <Route path="/sign-up" component={Signup} />
            <Route path="/sign-in" component={Signin} />
            <Route path="/create-profile" component={CreateProfile} />
            <Route path="/confirm-account/:emailToken" component={ConfirmAccount} />
            <Route component={NotFound} />
          </Switch>
          </main>
          <Footer />
          </div>
      </MantineProvider>
    </BrowserRouter>
  );
}


export default App;
