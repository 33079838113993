import React, { Component } from "react";

class Doctor extends Component {

    render() {
        return (
           <h1>Hello world</h1>
        )
    }
}

export default Doctor