import React, {Component} from 'react';
import gql from "graphql-tag";
import { Query } from "react-apollo";
import Error from "../components/Error";
import Loading from "../components/Loading";
import HouseList from '../components/HouseList';


const GET_HOUSES_QUERY = gql`
  query GetHouses($limit: Int!, $offset: Int) {
    houses(limit: $limit, offset: $offset) {
      id
      propertyID
      listingID
      property
    }
  }
`;

const LIMIT = 16;

class Houses extends Component {
  state = {
    hasMorePlaces: true
  };

  render() {
    return (
      <Query query={GET_HOUSES_QUERY} variables={{ limit: LIMIT, offset: 0 }}>
        {({ data, loading, error, fetchMore }) => {
          if (loading) return <Loading />;
          if (error) return <Error error={error} />;
          return (
            <>
              <HouseList houses={data.houses} />
              <div className="more">
                <button
                  disabled={!this.state.hasMorePlaces}
                  onClick={() =>
                    fetchMore({
                      variables: {
                        offset: data.houses.length
                      },
                      updateQuery: (previousResult, { fetchMoreResult }) => {
                        if (!fetchMoreResult) return previousResult;

                        if (fetchMoreResult.houses.length < LIMIT) {
                          this.setState({ hasMorePlaces: false });
                        }

                        return {
                            houses: [
                            ...previousResult.houses,
                            ...fetchMoreResult.houses
                          ]
                        };
                      }
                    })
                  }>
                  See More
                </button>
              </div>
            </>
          );
        }}
      </Query>
    );
  }
}

export default Houses