import React from "react";
import { Button, FileInput, Grid, Group, Text, Title } from '@mantine/core';

const Images = ({ prevStep, nextStep, form }) => {

  const isFormValid = () => {
    return (
      form.values.selectedFiles && 
      form.values.selectedFiles.length >= 5
    );
  };

  return (
    <Grid>
      <Grid.Col>
        <Title order={3}>Property Type</Title>
        <FileInput
          multiple
          label="Upload Files"
          name="selectedFiles"
          accept="image/png,image/jpeg, image/jpg, image/webp"
          {...form.getInputProps("selectedFiles")} />
        <Text cz="sm" c="dimmed">* You need to upload at least 5 photos</Text>
      </Grid.Col>
      <Group position="right" mt="xl">
        <Button variant="contained" onClick={prevStep} >Previous</Button>
        <Button onClick={nextStep} disabled={!isFormValid()}>Next</Button>
      </Group>
    </Grid>
  );
};

export default Images;
