import React, { Component } from "react";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import { withRouter } from "react-router";
import Error from "../components/Error";
import Loading from "../components/Loading";
import { GET_CURRENT_USER_QUERY } from "../components/CurrentUser";

import {
    Paper,
    Title,
    Container,
    Button,
    PinInput,
    Center
} from '@mantine/core';

const CONFIRM_ACCOUNT_MUTATION = gql`
  mutation ConfirmAccount($emailToken: String!, $code: String!) {
    confirmAccount(emailToken: $emailToken, code: $code) {
    token
      user {
        firstName
        username
        gender
      }
    }
  }
`;

class ConfirmAccount extends Component {
    state = {
        emailToken: "",
        code: ""
    };

    handleChange = value => {
        this.setState({ "code": value });
    };

    isFormValid = event => {
        return this.state.code.length > 0;
    };

    handleCompleted = data => {
        localStorage.setItem("auth-token", data.confirmAccount.token);
        this.props.history.push("/");
    };

    handleUpdate = (cache, { data }) => {
        cache.writeQuery({
            query: GET_CURRENT_USER_QUERY,
            data: { me: data.confirmAccount.user }
        });
    };

    onError = () => {
        console.log(this.state)
    }


    render() {
        const { emailToken } = this.props.match.params;

        return (
            <Mutation
                mutation={CONFIRM_ACCOUNT_MUTATION}
                variables={{ emailToken: emailToken, code: this.state.code }}
                onError={this.onError}
                onCompleted={this.handleCompleted}
                update={this.handleUpdate}>
                {(confirmAccount, { error, loading }) => {
                    if (loading) return <Loading />;
                    return (
                        <form
                            className="signin"
                            onSubmit={e => {
                                e.preventDefault();
                                confirmAccount();
                            }}>

                            <Container size={420} my={40}>
                                <Title
                                    align="center"
                                    sx={(theme) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 900 })}
                                >
                                    Validate your code!
                                </Title>
                                <Error error={error} />
                                <Paper withBorder shadow="md" p={30} mt={30} radius="md">
                                    <Title align="center" order={4} my={20}>Enter the code we sent to your email</Title>

                                    <Center>
                                        <PinInput label="Code"
                                            length={7}
                                            size="sm"
                                            name="code"
                                            value={this.state.code}
                                            onChange={this.handleChange}
                                            error={error ? "Invalid Code" : ""}
                                        />
                                    </Center>
                                    <Button fullWidth mt="xl" type="submit" disabled={loading || !this.isFormValid()}>
                                        {loading ? "Validating Code..." : "Confirm Account"}
                                    </Button>
                                </Paper>
                            </Container>

                            {/* <Container component="main" maxWidth="xs">
                                <Box
                                    sx={{
                                        marginTop: 8,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                                        <LockOutlinedIcon />
                                    </Avatar>
                                    <Typography component="h1" variant="h5">
                                        Validate Code
                                    </Typography>
                                    <TextField
                                        variant="standard"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="code"
                                        label="Code"
                                        name="code"
                                        autoComplete="code"
                                        autoFocus
                                        value={this.state.code}
                                        onChange={this.handleChange}
                                        error={error ? true : false}
                                        helperText={error ? error.message.replace("GraphQL error: ", "") : ""}
                                    />
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        Validate Code
                                    </Button>
                                </Box>
                            </Container> */}
                        </form>
                    );
                }}
            </Mutation>
        );
    }
}

export default withRouter(ConfirmAccount);
