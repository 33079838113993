import React from "react";

const Confirmation = ({ prevStep, nextStep }) => {

  const Continue = (e) => {
    e.preventDefault();
    nextStep();
  };

  const Previous = (e) => {
    e.preventDefault();
    prevStep();
  };

  return (
 <div
      className="card card-body bg-white border-light col-md-6 mb-6"
    >
      <div className="mt-3">
        <p>
        We and selected third parties collect personal information as specified in the privacy policy and use cookies or similar technologies 
        for technical purposes and, with your consent, for other purposes as specified in the cookie policy. Denying consent may make related features unavailable.
        Use the “Accept” button to consent. Use the “Reject” button or close this notice to continue without accepting.
        </p>


        <button
          type="submit"
          className="btn btn-primary mr-2"
          onClick={Previous}
        >
          Previous
        </button>

        <button type="submit" className="btn btn-primary" onClick={Continue}>
          Submit
        </button>
      </div>
      </div>

  );
};

export default Confirmation;
