import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import CurrentUser from "../components/CurrentUser";
import Signout from "./Signout";
import logo from "../assets/img/logo.png";
import Gravatar from "react-gravatar";

import {
  createStyles,
  Container,
  Header,
  Menu,
  Group,
  Button,
  UnstyledButton,
  Title,
  Text,
  Divider,
  Select,
  Image,
  Box,
  Burger,
  Drawer,
  ScrollArea,
  rem,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
  IconLogout,
  IconHeart,
  IconStar,
  IconMessage,
  IconSettings,
  // IconPlayerPause,
  // IconTrash,
  IconSwitchHorizontal,
  IconChevronDown,
} from '@tabler/icons-react';

const useStyles = createStyles((theme) => ({
  link: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    textDecoration: 'none',
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    fontWeight: 500,
    fontSize: theme.fontSizes.lg,

    [theme.fn.smallerThan('sm')]: {
      height: rem(42),
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },

    ...theme.fn.hover({
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    }),
  },

  user: {
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    borderRadius: theme.radius.sm,
    transition: 'background-color 100ms ease',

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
    },

    [theme.fn.smallerThan('xs')]: {
      display: 'none',
    },
  },

  subLink: {
    width: '100%',
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    borderRadius: theme.radius.md,

    ...theme.fn.hover({
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
    }),

    '&:active': theme.activeStyles,
  },

  dropdownFooter: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
    margin: `calc(${theme.spacing.md} * -1)`,
    marginTop: theme.spacing.sm,
    padding: `${theme.spacing.md} calc(${theme.spacing.md} * 2)`,
    paddingBottom: theme.spacing.xl,
    borderTop: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]
      }`,
  },

  hiddenMobile: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  hiddenDesktop: {
    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },
}));

const HeaderMenu = () => {
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);
  const [userMenuOpened, setUserMenuOpened] = useState(false);
  const { classes, theme, cx } = useStyles();
  const history = useHistory();

  const handleChange = (value) => {
    localStorage.setItem("country", value);
    const pathName = history.location.pathname;
    history.push(pathName)
  }

  const countries =
    [
      { value: "US", label: "United States" },
      { value: "AU", label: "Australia" },
      { value: "NZ", label: "New Zealand" },
      { value: "CA", label: "Canada" },
      { value: "IN", label: "India" }
    ]

  return (
    <CurrentUser>
      {currentUser => (
        <Box pb={40}>
          <Container fluid>
            <Header height={60} px="md">
              <Group position="apart" sx={{ height: '100%' }}>
                <Title order={2}>
                  <Link to="/">
                    <Image maw={150} src={logo} alt="Listmaxx." />
                  </Link>
                </Title>
                <Group sx={{ height: '100%' }} spacing={0} className={classes.hiddenMobile}>
                  <Link to="/places/search" className={classes.link}>
                    Search Place
                  </Link>
                  <Link to="/create-place" className={classes.link}>
                    List your Place
                  </Link>
                  {currentUser && (
                    <Link to="/bookings" className={classes.link}>
                      My Bookings
                    </Link>
                  )}
                  <Select
                    size="xs"
                    sx={{margin: "20px" , width: "150px"}}
                    name="country"
                    placeholder="Pick Country"
                    data={countries}
                    onChange={handleChange}
                    defaultValue={localStorage.getItem("country") ? localStorage.getItem("country") : localStorage.setItem("country", "US")}
                  />
                </Group>

                {!currentUser && (
                  <Group className={classes.hiddenMobile}>
                    <Button variant="default">
                      <Link to="/sign-in" className={classes.link}>Log in</Link>
                    </Button>
                  </Group>
                )}
                {currentUser && (
                  <Menu
                    width={200}
                    position="bottom-end"
                    transitionProps={{ transition: 'pop-top-right' }}
                    onClose={() => setUserMenuOpened(false)}
                    onOpen={() => setUserMenuOpened(true)}
                    withinPortal
                  >
                    <Menu.Target>
                      <UnstyledButton
                        className={cx(classes.user, { [classes.userActive]: userMenuOpened })}
                      >
                        <Group spacing={7}>
                          <Gravatar
                            md5={currentUser.gravatarMd5}
                            className="rounded-circle"
                            size={30}
                          />
                          <Text weight={500} size="sm" sx={{ lineHeight: 1 }} mr={3}>
                            {currentUser.firstName}
                          </Text>
                          <IconChevronDown size={rem(12)} stroke={1.5} />
                        </Group>
                      </UnstyledButton>
                    </Menu.Target>
                    <Menu.Dropdown>
                      <Menu.Item
                        icon={<IconHeart size="0.9rem" color={theme.colors.red[6]} stroke={1.5} />}
                      >
                        Liked Places
                      </Menu.Item>
                      <Menu.Item
                        icon={<IconStar size="0.9rem" color={theme.colors.yellow[6]} stroke={1.5} />}
                      >
                        Saved Places
                      </Menu.Item>
                      <Menu.Item
                        icon={<IconMessage size="0.9rem" color={theme.colors.blue[6]} stroke={1.5} />}
                      >
                        Your comments
                      </Menu.Item>

                      <Menu.Label>Settings</Menu.Label>
                      <Menu.Item icon={<IconSettings size="0.9rem" stroke={1.5} />}>
                        Account settings
                      </Menu.Item>
                      <Menu.Item icon={<IconSwitchHorizontal size="0.9rem" stroke={1.5} />}>
                        Change account
                      </Menu.Item>
                      <Menu.Item icon={<IconLogout size="0.9rem" stroke={1.5} />}><Signout /></Menu.Item>

                      {/* <Menu.Divider />

                      <Menu.Label>Danger zone</Menu.Label>
                      <Menu.Item icon={<IconPlayerPause size="0.9rem" stroke={1.5} />}>
                        Pause subscription
                      </Menu.Item>
                      <Menu.Item color="red" icon={<IconTrash size="0.9rem" stroke={1.5} />}>
                        Delete account
                      </Menu.Item> */}
                    </Menu.Dropdown>
                  </Menu>
                )}

                <Burger size="xs" opened={drawerOpened} onClick={toggleDrawer} className={classes.hiddenDesktop} />
              </Group>
            </Header>

            <Drawer
              opened={drawerOpened}
              onClose={closeDrawer}
              size="100%"
              padding="md"
              title="Listmaxx"
              className={classes.hiddenDesktop}
              zIndex={1000000}
            >
              <ScrollArea h={`calc(100vh - ${rem(60)})`} mx="-md">
                <Divider my="sm" color={theme.colorScheme === 'dark' ? 'dark.5' : 'gray.1'} />

                <a href="/" className={classes.link}>
                  Home
                </a>
                <a href="/places/search" className={classes.link}>
                  Search Place
                </a>
                <Select
                  size="xs"
                  sx={{margin: "20px" , width: "150px"}}
                  name="country"
                  placeholder="Pick Country"
                  data={countries}
                  onChange={handleChange}
                  defaultValue={localStorage.getItem("country") ? localStorage.getItem("country") : localStorage.setItem("country", "US")}
                />
                <Divider my="sm" color={theme.colorScheme === 'dark' ? 'dark.5' : 'gray.1'} />

                <Group position="center" grow pb="xl" px="md">
                  <Button size="xs" variant="default">
                    <a href="/sign-in" className={classes.link}>Log in</a></Button>
                </Group>
              </ScrollArea>
            </Drawer>
          </Container>
        </Box>
      )}
    </CurrentUser>
  )
}


export default HeaderMenu;
