import React, { Component } from "react";
import PropTypes from "prop-types";
import NoData from "./NoData";
import JobCard from "../components/JobCard";

class JobList extends Component {
  static propTypes = {
    jobs: PropTypes.array.isRequired
  };

  render() {
    const { jobs } = this.props;

    if (jobs.length === 0) return <NoData />;

    return (
      <div className="places">
        {jobs.map(job => (
          <JobCard key={job.id} job={job} />
        ))}
      </div>
    );
  }
}

export default JobList;
