import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { formatCurrency, formatText } from "../lib/helpers";

function HouseCard({ house }) {
    const property = JSON.parse(house.property);

    return (
        <div className="card-column">
            <div className="card">
                <Link to={`/property/${property.property_id}`}>
                    <img src={property.thumbnail} alt={property.address.line} />
                </Link>
                <div className="card-body">
                    {property.prop_status} | {property.prop_type}
                    <h6><span className="dot"></span> {formatText(property.prop_status)} | {formatText(property.prop_type)}</h6>
                    <h6 className="name"><b>{formatCurrency(property.price)}</b>
                        {property.client_display_flags.price_change !== 0 &&
                            <span> {property.client_display_flags.price_change}</span>
                        }
                    </h6>
                    {property.prop_type === 'single_family' &&
                        <h6 className="location">{property.beds} bd | {property.baths} ba |<span>&nbsp;</span>
                            {property.building_size?.size} <span>&nbsp;</span>
                            {property.building_size?.units} 
                            </h6>
                    }
                    <h6 className="name">
                        <Link to={`/property/${property.id}`}>
                            {property.address.line},
                                            {property.address.city},
                                            {property.address.state_code}-
                                            {property.address.postal_code}
                        </Link>
                    </h6>
                </div>
            </div>
        </div>
    )

}

HouseCard.propTypes = {
    house: PropTypes.object.isRequired
};

export default HouseCard;
