import React, { Component } from "react";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import Error from "../components/Error";
import Loading from "../components/Loading";
import PlaceList from "../components/PlaceList";
import { Container, Button, Center, Space } from "@mantine/core";

const GET_PLACES_QUERY = gql`
  query GetPlaces($country: String, $limit: Int!, $offset: Int) {
    places(country: $country, limit: $limit, offset: $offset) {
      id
      slug
      name
      propertyType
      location
      features
      locationDetails {
        placeName
        stateName
      }
      description
      pricePerNight
      photoUrls
      maxGuests
      bedrooms
      bathrooms
      beds
    }
  }
`;

const LIMIT = 18;
const OFFSET = 0;

class Home extends Component {
  state = {
    country: "",
    hasMorePlaces: true
  };

  componentDidMount() {
    console.log("component mount")
  }

  render() {
    return (
      <Query query={GET_PLACES_QUERY} variables={{ country: localStorage.getItem("country") , 
        limit: LIMIT, offset: OFFSET }} fetchPolicy="network-only">
        {({ data, loading, error, fetchMore }) => {
          if (loading) return <Loading />;
          if (error) return <Error error={error} />;
          return (
            <Container fluid>
              <Space size="lg" sx={{ marginTop: "20px" }} />
              <PlaceList places={data.places} />
              <Center>
                <Button sx={{ marginTop: "20px" }}
                  variant="default"
                  disabled={!this.state.hasMorePlaces}
                  onClick={() =>
                    fetchMore({
                      variables: {
                        offset: data.places.length
                      },
                      updateQuery: (previousResult, { fetchMoreResult }) => {
                        if (!fetchMoreResult) return previousResult;

                        if (fetchMoreResult.places.length < LIMIT) {
                          this.setState({ hasMorePlaces: false });
                        }

                        return {
                          places: [
                            ...previousResult.places,
                            ...fetchMoreResult.places
                          ]
                        };
                      }
                    })
                  }>
                  See More
                </Button>
              </Center>
            </Container>
          );
        }}
      </Query>
    );
  }
}

export default Home;
export { GET_PLACES_QUERY, LIMIT, OFFSET };
