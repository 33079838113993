import React, { Component } from "react";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import Error from "../components/Error";
import Loading from "../components/Loading";
import JobList from "../components/JobList";
import JobSearchCriteria from "../components/custom-inputs/JobSearchCriteria";

const SEARCH_JOBS_QUERY = gql`
  query SearchJobs($limit: Int!, $offset: Int, $filter: JobFilter!) {
    jobs(limit: $limit, offset: $offset, filter: $filter) {
      id
      jobTitle
      companyName
      jobLocation
      datePosted
      jobSource
      jobType
      jobCategory
      jobUrl
    }
  }
`;

const LIMIT = 10;

class Jobs extends Component {
  state = {
    hasMoreJobs: true,
    criteria: {}
  };

  handleCriteriaChange = criteria => {
    this.setState({ criteria: criteria });
  };

  render() {
    return (
      <div className="search">
        <JobSearchCriteria
          criteria={this.state.criteria}
          onChange={this.handleCriteriaChange}
        />
        <div className="search-results">
          <Query
            query={SEARCH_JOBS_QUERY}
            variables={{
              limit: LIMIT, offset: 0,
              filter: this.state.criteria
            }}>
            {({ data, loading, error, fetchMore }) => {
              if (loading) return <Loading />;
              if (error) return <Error error={error} />;
              return (
                <>
                  <JobList jobs={data.jobs} />
                  <div className="more">
                    <button
                      disabled={!this.state.hasMoreJobs}
                      onClick={() =>
                        fetchMore({
                          variables: {
                            offset: data.jobs.length
                          },
                          updateQuery: (previousResult, { fetchMoreResult }) => {
                            if (!fetchMoreResult) return previousResult;

                            if (fetchMoreResult.jobs.length < LIMIT) {
                              this.setState({ hasMoreJobs: false });
                            }

                            return {
                              jobs: [
                                ...previousResult.jobs,
                                ...fetchMoreResult.jobs
                              ]
                            };
                          }
                        })
                      }>
                      See More
                    </button>
                  </div>
                </>
              );
            }}
          </Query>
        </div>
      </div>
    );
  }
}

export default Jobs;
