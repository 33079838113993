import React, { Component } from "react";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import Error from "../components/Error";
import Loading from "../components/Loading";
import Booking from "../components/Booking";
import { Container } from '@mantine/core';
import CurrentUser from "../components/CurrentUser";

const GET_MY_BOOKINGS_QUERY = gql`
  query GetMyBookings($limit: Int!, $offset: Int) {
    me {
      bookings(limit: $limit, offset: $offset) {
        id
        startDate
        endDate
        totalPrice
        state
        adults
        children
        infants
        pets
        place {
          id
          name
          slug
          photoUrls
          pricePerNight
        }
      }
    }
  }
`;

const LIMIT = 15;
const OFFSET = 0;

class MyBookings extends Component {
  state = {
    hasMoreBookings: true
  };

  render() {
    return (
      <CurrentUser>
        {CurrentUser => (
            <Query query={GET_MY_BOOKINGS_QUERY} variables={{ limit: LIMIT, offset: OFFSET }} >
          {({ data, loading, error, fetchMore }) => {
            if (loading) return <Loading />;
            if (error) return <Error error={error} />;
            return (
              <Container size="xl">
                <h1>My Bookings</h1>
                {data.me.bookings.map(booking => (
                  <Booking key={booking.id} booking={booking} />
                ))}
                {/* <div className="more">
                  <button className="ui button secondary"
                    disabled={!this.state.hasMoreBookings}
                    onClick={() =>
                      fetchMore({
                        variables: {
                          offset: data.me.bookings.length
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {

                          if (!fetchMoreResult) return previousResult;

                          if (fetchMoreResult.me.bookings.length < LIMIT) {
                            this.setState({ hasMoreBookings: false });
                          }

                          return {
                            bookings: [
                              ...previousResult.me.bookings,
                              ...fetchMoreResult.me.bookings
                            ]
                          };
                        }
                      })
                    }>
                    See More
                  </button>
                </div> */}
              </Container>
            );
          }}
        </Query>
        )}
      </CurrentUser>
    );
  }
}

export default MyBookings;
export { GET_MY_BOOKINGS_QUERY };