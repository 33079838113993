import React, { Component } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { distanceInWordsFromNow } from "../lib/helpers";
import { Title, Text, Group, rem } from "@mantine/core";
import Gravatar from "react-gravatar";

const CREATE_REVIEW_SUBSCRIPTION = gql`
  subscription CreateReview($placeId: ID!) {
    createReview(placeId: $placeId) {
      id
      comment
      rating
      insertedAt
      user {
        username
      }
    }
  }
`;

class PlaceReviews extends Component {
  static propTypes = {
    place: PropTypes.object.isRequired,
    subscribeToCreateReviews: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.subscribeToCreateReviews({
      document: CREATE_REVIEW_SUBSCRIPTION,
      variables: { placeId: this.props.place.id },
      updateQuery: this.handleCreateReview
    });
  }

  handleCreateReview = (prev, { subscriptionData }) => {
    if (!subscriptionData.data) return prev;
    const review = subscriptionData.data.createReview;
    let updatedReviews = [...prev.place.reviews, review];;

    return {
      place: {
        ...prev.place,
        reviews: updatedReviews
      }
    };
  };

  render() {
    const { place } = this.props;

    return (
      <>
        <Title order={2} sx={{ marginBottom: "10px" }}>Reviews</Title>
        {place.reviews.map(review => (
          <div>
            <Group>
              <Gravatar
                md5={review.user.gravatarMd5}
                className="rounded-circle"
                size={25}
              />
              <div>
                <Text size="sm">{review.user.username}</Text>
                <Text size="xs" color="dimmed">
                  {distanceInWordsFromNow(review.insertedAt)}
                </Text>
              </div>
            </Group>
            {/* <Group position="right">
              <Rating value={review.rating} readOnly />
              <Text>{review.rating}</Text>
            </Group> */}
            <Text sx={{ paddingLeft: rem(54), paddingTop: "5px", paddingBottom: "20px" }} size="sm">
              {review.comment}
            </Text>
          </div>
        ))}
        {/* <CurrentUser>
          {currentUser => (
            <>
              {!currentUser && (
                <div className="reviews">
                  <Link className="button" to="/sign-in">
                    Sign In To Create a Review
                  </Link>
                </div>
              )}
              {currentUser && <CreateReview place={place} />}
            </>
          )}
        </CurrentUser> */}
      </>
    );
  }

}

export default PlaceReviews;
