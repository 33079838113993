import React from "react";
import { Button, Title, TextInput, Textarea, Group } from '@mantine/core';


const Basic = ({ prevStep, nextStep, form }) => {

  const isFormValid = event => {
    return (
      form.values.name.length > 0 &&
      form.values.description.length > 0
    );
  };

  return (
    <>
      <Title order={2}>
        Basic Information
      </Title>
      <TextInput mt="sm" label="Title of the Place" placeholder="Title of the Location " {...form.getInputProps('name')} />
      <Textarea mt="sm" autosize minRows={4} label="Description of the place" placeholder="enter details of the place." {...form.getInputProps('description')} />

      <Group position="right" mt="xl">
        <Button variant="contained" onClick={prevStep} >Previous</Button>
        <Button onClick={nextStep} disabled={!isFormValid()}>Next</Button>
      </Group>
    </>
  );
};

export default Basic;
