import React, { Component } from "react";
import { withRouter } from "react-router";
import { withApollo } from "react-apollo";
import { Link } from "react-router-dom";

class Signout extends Component {
  handleClick = () => {
    localStorage.removeItem("auth-token");

    this.props.client.resetStore();

    this.props.history.push("/");
  };

  render() {
    return (
      <Link to="#0" onClick={this.handleClick}>Signout</Link>
    );
  }
}

export default withRouter(withApollo(Signout));
