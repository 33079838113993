import React from "react";
import PropTypes from "prop-types";
import { formatYYYYMMDD } from "../../lib/helpers";
import data from "../../data/data.json";
import debounce from "lodash.debounce";
import { DatePickerInput } from '@mantine/dates';
import { NumberInput, Container, Checkbox, Select, Button, Space, Text, SimpleGrid, Group } from "@mantine/core";
// import { useForm } from '@mantine/form';

const PlaceSearchCriteria = ({ criteria, onChange }) => {
  // const form = useForm({
  //   validateInputOnChange: true,
  //   initialValues: criteria,
  //   transformValues: (values) => ({
  //     minAge: Number(values.minAge) || 0,
  //   }),
  // });

  const notifyOnChange = (newCriteria) => {
    onChange(newCriteria);
  };

  const clear = () => {
    notifyOnChange({});
  };

  const handleDateRange = (value) => {

    const currentRange = {
      ...criteria.availableBetween
    };

    if (value) {
      currentRange.startDate = formatYYYYMMDD(value[0]);
      currentRange.endDate = formatYYYYMMDD(value[1]);
    }

    const newCriteria = {
      ...criteria,
      availableBetween: currentRange
    };

    if (currentRange.startDate && currentRange.endDate) {
      notifyOnChange(newCriteria);
    }
  }

  const handleMinPrice = debounce(value => {

    let newValue;

    if (value) {
      newValue = value;
    } else {
      newValue = 1;
    }

    const newCriteria = {
      ...criteria,
      minPrice: newValue
    };

    notifyOnChange(newCriteria);
  }, 500)

  const handleMaxPrice = debounce(value => {

    let newValue;

    if (value) {
      newValue = value;
    } else {
      newValue = undefined;
    }

    const newCriteria = {
      ...criteria,
      maxPrice: newValue
    };

    notifyOnChange(newCriteria);
  }, 500)

  const handleGuestCountChange = debounce(value => {

    let newCount;

    if (value) {
      newCount = value;
    } else {
      newCount = undefined;
    }

    const newCriteria = {
      ...criteria,
      guestCount: newCount
    };

    notifyOnChange(newCriteria);
  }, 500)

  const handleCheckbox = debounce(event => {
    let newValue = event.target.checked;

    const newCriteria = {
      ...criteria,
      [event.target.name]: newValue
    };

    notifyOnChange(newCriteria);

  }, 500)

  const handlePropertyTypeChange = debounce(value => {

    let newValue;

    if (value) {
      newValue = value;
    } else {
      newValue = undefined;
    }

    const newCriteria = {
      ...criteria,
      propertyType: newValue
    };

    notifyOnChange(newCriteria);
  }, 500);

  return (
    <Container>
      <Select
        searchable
        size="xs"
        name="propertyType"
        placeholder="Select One"
        label="Property Type"
        defaultValue={criteria.propertyType}
        key={criteria.propertyType}
        data={data.property_types}
        // {...form.getInputProps('propertyType')}
        onChange={event => {
          handlePropertyTypeChange(event);
        }}
      />
      <Space h="xxs" />
      <DatePickerInput
        size="xs"
        type="range"
        label="Available Dates"
        placeholder="Available Dates"
        defaultValue={criteria.availableBetween}
        onChange={handleDateRange}
        mx="auto"
        maw={400}
      />
      <Space h="xxs" />
      <Text sx={{ fontSize: "0.875rem" }} fw={500}>Guests</Text>
      <Space h="xxs" />
      <NumberInput
        size="xs"
        name="guestCount"
        noClampOnBlur
        key={criteria.guestCount}
        defaultValue={criteria.guestCount || 1}
        min={1}
        max={20}
        onChange={handleGuestCountChange}
      />
      <Space h="xxs" />
      <Text sx={{ fontSize: "0.875rem" }} fw={500}>Price Range</Text>
      <Space h="xxs" />
      <SimpleGrid cols={2}>
        <NumberInput
          size="xs"
          name="minPrice"
          key={criteria.minPrice}
          defaultValue={criteria.minPrice}
          label="Min"
          noClampOnBlur
          onChange={handleMinPrice}
        />
        <NumberInput
          size="xs"
          name="maxPrice"
          defaultValue={criteria.maxPrice < criteria.minPrice ? criteria.minPrice : criteria.maxPrice}
          label="Max"
          noClampOnBlur
          key={criteria.maxPrice}
          min={criteria.minPrice}
          max={criteria.minPrice}
          onChange={handleMaxPrice}
        />
      </SimpleGrid>

      <Space h="xxs" />
      <Text sx={{ fontSize: "0.875rem" }} fw={500}>Amenities</Text>
      <Space h="xxs" />
      <span key="petFriendly">
        <Checkbox
          size="xs"
          key={criteria.petFriendly}
          name="petFriendly"
          label="Pet Friendly"
          defaultChecked={criteria.petFriendly}
          onChange={(event) => {
            handleCheckbox(event);
          }}
        />
      </span>
      <Space h="xxs" />
      <span key="wifi">
        <Checkbox
          size="xs"
          key={criteria.wifi}
          name="wifi"
          label="Wireless"
          defaultChecked={criteria.wifi}
          onChange={(event) => {
            handleCheckbox(event);
          }}
        />
      </span>
      <Space h="xxs" />
      <span key="pool">
        <Checkbox
          size="xs"
          key={criteria.pool}
          name="pool"
          label="Pool"
          defaultChecked={criteria.pool}
          onChange={(event) => {
            handleCheckbox(event);
          }}
        />
      </span>
      <Space h="xxs" />
      <span key="parking">
        <Checkbox
          size="xs"
          key={criteria.parking}
          name="parking"
          label="Parking"
          defaultChecked={criteria.parking}
          onChange={(event) => {
            handleCheckbox(event);
          }}
        />
      </span>
      <Space h="xxs" />
      <span key="kitchen">
        <Checkbox
          size="xs"
          name="kitchen"
          label="Kitchen"
          key={criteria.kitchen}
          defaultChecked={criteria.kitchen}
          onChange={(event) => {
            handleCheckbox(event);
          }}
        />
      </span>
      <Space h="xxs" />
      <span key="patio">
        <Checkbox
          size="xs"
          name="patio"
          label="Patio"
          key={criteria.patio}
          defaultChecked={criteria.patio}
          onChange={(event) => {
            handleCheckbox(event);
          }}
        />
      </span>
      <Space h="xxs" />
      <span key="bbqGrill">
        <Checkbox
          size="xs"
          name="bbqGrill"
          label="BBQ Grill"
          key={criteria.bbqGrill}
          defaultChecked={criteria.bbqGrill}
          onChange={(event) => {
            handleCheckbox(event);
          }}
        />
      </span>
      <Space h="xxs" />
      <span key="mediaRoom">
        <Checkbox
          size="xs"
          name="mediaRoom"
          label="Media Room"
          key={criteria.mediaRoom}
          defaultChecked={criteria.mediaRoom}
          onChange={(event) => {
            handleCheckbox(event);
          }}
        />
      </span>
      <Space h="xxs" />
      <span key="hotTub">
        <Checkbox
          size="xs"
          name="hotTub"
          label="Hot Tub"
          key={criteria.hotTub}
          defaultChecked={criteria.hotTub}
          onChange={(event) => {
            handleCheckbox(event);
          }}
        />
      </span>
      <Space h="xxs" />
      <span key="washer">
        <Checkbox
          size="xs"
          name="washer"
          label="Washer"
          key={criteria.washer}
          defaultChecked={criteria.washer}
          onChange={(event) => {
            handleCheckbox(event);
          }}
        />
      </span>
      <Space h="xxs" />
      <span key="tv">
        <Checkbox
          size="xs"
          name="tv"
          label="Television"
          key={criteria.tv}
          defaultChecked={criteria.tv}
          onChange={(event) => {
            handleCheckbox(event);
          }}
        />
      </span>
      <Space h="xxs" />
      <Group>
        <Button variant="filled" size="xs" onClick={clear}>Reset</Button>
      </Group>

    </Container>
  )
}

PlaceSearchCriteria.propTypes = {
  criteria: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default PlaceSearchCriteria;