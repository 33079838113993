import React, { useState } from "react";
import gql from "graphql-tag";
import PropTypes from "prop-types";
import { Mutation } from "react-apollo";
import Error from "./Error";
import BookingTotals from "./BookingTotals";
import BookingCalendar from "./custom-inputs/BookingCalendar";
import { formatYYYYMMDD } from "../lib/helpers";
import { GET_MY_BOOKINGS_QUERY } from "../pages/MyBookings";
import { Center, Button, Grid, Text, TextInput, Modal, rem, NumberInput } from "@mantine/core";
import { useDisclosure } from '@mantine/hooks';

const CREATE_BOOKING_MUTATION = gql`
  mutation CreateBooking(
    $placeId: ID!
    $startDate: Date!
    $endDate: Date!
    $adults: Int
    $children: Int
    $infants: Int
    $pets: Int
  ) {
    createBooking(placeId: $placeId, startDate: $startDate, endDate: $endDate, adults: $adults, children: $children, infants: $infants, pets: $pets) {
      id
    }
  }
`;

const CreateBooking = ({ place }) => {
  const [state, setState] = useState({
    startDate: null, endDate: null
  });
  const [opened, { open, close }] = useDisclosure(false);
  const [adults, setAdults] = useState(1)
  const [children, setChildren] = useState(0)
  const [infants, setInfants] = useState(0)
  const [pets, setPets] = useState(0)

  const handleDayClick = newDateRange => {
    setState(newDateRange);
  };

  const clearState = () => {
    setState({ state: null });
    setAdults(1);
    setChildren(0);
    setInfants(0);
    setPets(0);

  };

  const hasPickedDates = () => {
    return state.startDate && state.endDate;
  };

  const hasGuestsEntered = () => {
    return adults > 0
  }

  const adultsDisplay = "Adults " + adults;
  const childrenDisplay = ", Children " + children;
  const infantsDisplay = ", Infants " + infants;
  const petsDisplay = ", Pets " + pets;

  const guestsDisplay = (children > 0) ? adultsDisplay + childrenDisplay
    : (infants > 0) ? adultsDisplay + childrenDisplay + infantsDisplay
      : (pets > 0) ? adultsDisplay + childrenDisplay + infantsDisplay + petsDisplay
        : adultsDisplay

  return (
    <>
      <Modal opened={opened} onClose={close} title="Guests" styles={{ title: { fontSize: "20px", fontWeight: 800 } }}>
        <Grid>
          <Grid.Col span={6}>
            <Text>
              Adults<Text span color="dimmed"> (Age 13+)</Text>
            </Text>
          </Grid.Col>
          <Grid.Col span={6}>
            <NumberInput
              name="adults"
              min={1}
              max={place.maxGuests}
              value={adults}
              onChange={setAdults}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Text>
              Children<Text span color="dimmed"> (Ages 2-12)</Text>
            </Text>
          </Grid.Col>
          <Grid.Col span={6}>
            <NumberInput
              label="Chilren"
              name="children"
              min={0}
              max={place.maxGuests - adults}
              value={children}
              onChange={setChildren}
            />
          </Grid.Col>

          <Grid.Col span={6}>
            <Text>
              Infants<Text span color="dimmed"> (Under 2)</Text>
            </Text>
          </Grid.Col>
          <Grid.Col span={6}>
            <NumberInput
              label="Infants"
              name="infants"
              min={0}
              max={5}
              value={infants}
              onChange={setInfants}
            />
          </Grid.Col>

          <Grid.Col span={6}>
            <Text>
              Pets<Text span color="dimmed"> (Dogs/Cats)</Text>
            </Text>
          </Grid.Col>
          <Grid.Col span={6}>
            <NumberInput
              label="Pets"
              name="pets"
              min={0}
              max={5}
              value={pets}
              onChange={setPets}
            />
          </Grid.Col>
        </Grid>
      </Modal >
      <Mutation
        mutation={CREATE_BOOKING_MUTATION}
        variables={{
          placeId: place.id,
          startDate: formatYYYYMMDD(state.startDate),
          endDate: formatYYYYMMDD(state.endDate),
          adults: adults,
          children: children,
          infants: infants,
          pets: pets
        }}
        onCompleted={clearState}
        refetchQueries={[{ query: GET_MY_BOOKINGS_QUERY }]}>
        {(createBooking, { loading, error }) => (
          <div>
            <Error error={error} />
            <BookingCalendar
              bookings={place.bookings}
              selectedRange={state}
              onDayClick={handleDayClick}

            />
            {hasPickedDates() && (
              <BookingTotals
                startDate={state.startDate}
                endDate={state.endDate}
                pricePerNight={place.pricePerNight}
              />
            )}
            <Center>
              <TextInput
                sx={{ marginBottom: rem(10) }}
                styles={{ placeholder: { fontSize: "20px", fontWeight: 800 } }}
                placeholder={guestsDisplay}
                onClick={open}
              />
            </Center>
            <Center>
              <Button
                variant="filled"
                disabled={loading || !hasPickedDates() || !hasGuestsEntered()}
                onClick={createBooking}>
                Book These Dates
              </Button>
            </Center>
          </div>
        )}
      </Mutation>
    </>


  )
}

CreateBooking.propTypes = {
  place: PropTypes.object.isRequired
};

export default CreateBooking;
