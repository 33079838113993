import React, { Component } from "react";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import Error from "../components/Error";
import Loading from "../components/Loading";
import PlaceDetails from "../components/PlaceDetails";

const GET_PLACE_QUERY = gql`
  query GetPlace($slug: String!) {
    place(slug: $slug) {
      id
      slug
      name
      location
      locationDetails {
        placeName
        stateName
      }
      reviewStats {
        numOfReviews
        avgReview
      }
      photoUrls
      description
      propertyType
      pricePerNight
      maxGuests
      bedrooms
      bathrooms
      beds
      wifi
      pool
      petFriendly
      bbqGrill
      hotTub
      patio
      firePit
      poolTable
      fireplace
      mediaRoom
      tv
      bookings {
        id
        startDate
        endDate
        totalPrice
      }
      reviews(limit: 10, offset: 0) {
        id
        comment
        rating
        insertedAt
        user {
          username
          gravatarMd5
        }
      }
    }
  }
`;

class Place extends Component {
  render() {
    const { slug } = this.props.match.params;

    return (
      <Query
        query={GET_PLACE_QUERY}
        variables={{ slug: slug }}
        fetchPolicy="network-only">
        {({ data, loading, error, subscribeToMore }) => {
          if (loading) return <Loading />;
          if (error) return <Error error={error} />;
          return (
            <div className="place">
              <PlaceDetails place={data.place} subscribeToBookingChanges={subscribeToMore} subscribeToCreateReviews={subscribeToMore}/>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default Place;
export { GET_PLACE_QUERY };
