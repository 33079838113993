import React from "react";
import { Button, NumberInput, Grid, Group, Title } from '@mantine/core';

const Pricing = ({ prevStep, nextStep, form }) => {

  const isFormValid = event => {
    return (
      form.values.pricePerNight > 0
    );
  };

  return (
    <Grid>
      <Grid.Col>
        <Title order={3}>Property Type</Title>
        <NumberInput
          size="sm"
          mt="sm"
          name="pricePerNight"
          label="Price Per Night"
          placeholder="0"
          max={1000}
          {...form.getInputProps('pricePerNight')}
        />
      </Grid.Col>
      <Group position="right" mt="xl">
        <Button variant="contained" onClick={prevStep} >Previous</Button>
        <Button onClick={nextStep} disabled={!isFormValid()}>Next</Button>
      </Group>
    </Grid>
  );
};

export default Pricing;
