import React from "react";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import Error from "./Error";
import Loading from "./Loading";
import RequireSignIn from "./RequireSignIn";

import Basic from "./profiles-multi/Basic";
import Images from "./profiles-multi/Images";
import Confirmation from "./profiles-multi/Confirmation";

import { useHistory } from "react-router-dom";
import Compressor from 'compressorjs';

const CREATE_PROFILE_MUTATION = gql`
mutation CreateProfile(
    $dob: Date
    $marital: String!
    $lookingFor: String!
    $gender: String!
    $location: String!
    $occupation: String!
    $intent: String!
    $education: String!
    $seeking: String!
    $smoking: String!
    $drinking: String!
    $drugs: String!
    $religion: String!
    $haveKids: String!
    $wantKids: String!
    $eyeColor: String!
    $hairColor: String!
    $bodyType: String!
    $longestRelationship: Int!
    $pets: String!
    $ownCar: String!
    $hasDebt: String!
    $employed: String!
    $selectedFiles: [Upload]!
    $aboutSelf: String!
    $aboutPartner: String!
    $isCompleted: Boolean

) {
  createProfile(
    dob: $dob
    marital: $marital
    lookingFor: $lookingFor
    gender: $gender
    location: $location
    occupation: $occupation
    intent: $intent
    education: $education
    seeking: $seeking
    smoking: $smoking
    drinking: $drinking
    drugs: $drugs
    religion: $religion
    havekids: $haveKids
    wantKids: $wantKids
    eyeColor: $eyeColor
    hairColor: $hairColor
    bodyType: $bodyType
    longestRelationship: $longestTelationship
    pets: $pets
    ownCar: $ownCar
    hasDebt: $hasDebt
    employed: $employed
    photoUrls: $selectedFiles
    aboutSelf: $aboutSelf
    aboutPartner: $aboutPartner
    isCompleted: $isCompleted
  ) {
    id
  }
}
`;

const CreateProfile = () => {
  const history = useHistory();

  const [step, setStep] = React.useState(1);

  const [values, setValues] = React.useState({
    dob: "",
    marital: "",
    lookingFor: "",
    gender: "",
    location: "",
    occupation: "",
    intent: "",
    education: "",
    seeking: "",
    smoking: "",
    drinking: "",
    drugs: "",
    religion: "",
    haveKids: "",
    wantKids: "",
    eyeColor: "",
    hairColor: "",
    bodyType: "",
    longestTelationship: 0,
    pets: "",
    ownCar: "",
    debt: "",
    employed: "",
    selectedFiles: null,
    aboutSelf: "",
    aboutPartner: "",
    isCompleted: false

  });

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    const images = [];
    for (let index = 0; index < files.length; index++) {

      // console.log(files[index])
      new Compressor(files[index], {
        quality: 0.2,

        success(result) {
          images.push(result)
        },
        error(err) {
          console.log(err.message);
        },
      })

    }

    setValues({
      ...values,
      [e.target.name]: images,
    });
  };

  const handleChange = (e) => {

    const value = e.target.value;
    console.log(value)
    setValues({ ...values, [e.target.name]: value });
  };

  const handleCompleted = () => {
    history.push("/");
  };

//   const handleUpdate = (cache, { data: { createPlace } }) => {
//     const data = cache.readQuery({
//       query: GET_PLACES_QUERY,
//       variables: { limit: LIMIT, offset: OFFSET },
//     });
//     data.places.unshift(createPlace);
//     cache.writeQuery({
//       query: GET_PLACES_QUERY,
//       variables: { limit: LIMIT, offset: OFFSET },
//       data,
//     });
//   };

  const onError = () => {
    console.log("Error creating place")
  };

  return (
    <div className="d-flex justify-content-center">
      <RequireSignIn>
        <Mutation
          mutation={CREATE_PROFILE_MUTATION}
          variables={{
            dob:   values.dob,
            marital:   values.marital,
            lookingFor:   values.lookingFor,
            gender:   values.gender,
            location:   values.location,
            occupation:   values.occupation,
            intent:   values.intent,
            education:   values.education,
            seeking:   values.seeking,
            smoking:   values.smoking,
            drinking:   values.drinking,
            drugs:   values.drugs,
            religion:   values.religion,
            haveKids:   values.haveKids,
            wantKids:   values.wantKids,
            eyeColor:   values.eyeColor,
            hairColor:   values.hairColor,
            bodyType:   values.bodyType,
            longestTelationship: values.longestTelationship,
            pets:   values.pets,
            ownCar:   values.ownCar,
            debt:   values.debt,
            employed:   values.employed,
            selectedFiles:   values.selectedFiles,
            aboutSelf:   values.aboutSelf,
            aboutPartner:   values.aboutPartner,
            isCompleted:   values.isCompleted

          }}
          onCompleted={handleCompleted}
          // update={handleUpdate}
          onError={onError}
        >
          {(createProfile, { loading, error }) => {
            if (loading) return <Loading />;
            if (error) return <Error />;
            console.log(error);
            console.log(values);
            switch (step) {
              case 1:
                return (
                  <Basic
                    nextStep={nextStep}
                    values={values}
                    handleChange={handleChange}
                  />
                );
              case 2:
                return (
                  <Images
                    prevStep={prevStep}
                    nextStep={nextStep}
                    handleChange={handleChange}
                    values={values}
                    handleFileChange={handleFileChange}
                  />
                );
              /* falls through */
              case 3:
                return (
                  <Confirmation
                    prevStep={prevStep}
                    nextStep={nextStep}
                  />
                );
              /* falls through */
              case 4:
                createProfile();
              /* falls through */
              default:
                return <div>Step not found</div>;
            }
          }}
        </Mutation>
      </RequireSignIn>
    </div>
  );
};

export default CreateProfile;