import React from "react";
import data from "../../data/data.json";

const Basic = ({ prevStep, nextStep, handleChange, values }) => {
  // for continue event listener
  const Continue = (e) => {
    e.preventDefault();
    nextStep();
  };

  const Previous = (e) => {
    e.preventDefault();
    prevStep();
  };


  const isFormValid = event => {
    return (
      values.dob.length > 0 &&
      values.marital.length > 0
    );
  };

  return (
    <div
      className="card card-body bg-white border-light col-md-4 mb-6"
    >
      <h2 className="h5 mb-4">Basic Information</h2>
      <div className="row">
        <div className="col-md-12 mb-3">
          <div className="form-group">
            <label htmlFor="name">Date of Birth</label>
            <input
              className="form-control datepicker"
              name="dob"
              id="dob"
              type="text"
              required
              value={values.dob}
              onChange={handleChange}
              placeholder="date of birth"
            />
          </div>
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col-md-12 mb-3">
          <div className="form-group">
            <label htmlFor="marital">Marital Status</label>
            <select
              className="form-control"
              name="marital"
              id="marital"
              value={values.marital}
              key={values.marital}
              onChange={handleChange}>
              {data.marital.map((marital) => {
                return <option key={marital.id} value={marital.name} >{marital.display}</option>
              })}
            </select>
          </div>
        </div>
      </div>

      <div className="mt-3">
        <button
          type="submit"
          className="btn btn-primary mr-2"
          onClick={Previous}
        >
          Previous
        </button>

        <button type="submit" className="btn btn-primary" disabled={!isFormValid()} onClick={Continue}>
          Next
        </button>
      </div>
    </div>
  );
};

export default Basic;
