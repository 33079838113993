import React, { Component } from "react";
import PropTypes from "prop-types";
import PlaceCard from "../components/PlaceCard";
import NoData from "./NoData";
import { Grid}  from '@mantine/core';

class PlaceList extends Component {
  static propTypes = {
    places: PropTypes.array.isRequired
  };

  render() {
    const { places } = this.props;

    if (places.length === 0) return <NoData />;

    return (
      <Grid gutter="xs">
        {places.map(place => (
          <Grid.Col key={place.id} xs={6} md={2} lg={2} xl={2}>
            <PlaceCard key={place.id} place={place} />
          </Grid.Col>
        ))}
      </Grid>
    );
  }
}

export default PlaceList;
