import React from "react";
import SigninForm from "../components/SigninForm";
import CurrentUser from "../components/CurrentUser";
import { Center, Container, Text } from "@mantine/core";

const RequireSignIn = props => (
  <CurrentUser>
    {currentUser => {
      if (!currentUser) {
        return (
          <>
            <Container>
              <Center>
                <Text color="orange">Please sign in first...</Text>
              </Center>
              <SigninForm />
            </Container>
          </>
        );
      }
      return props.children;
    }}
  </CurrentUser>
);

export default RequireSignIn;
