import React from "react";
import { useDisclosure } from '@mantine/hooks';
import { LoadingOverlay, Loader, Center } from '@mantine/core';

const Loading = () => {
  const [visible] = useDisclosure(false);
  return (
    <Center>
      <LoadingOverlay visible={visible} overlayBlur={2} />
      <Loader variant="bars" size="xl" />
    </Center>
  )
}

export default Loading;