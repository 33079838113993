import React, { Component } from "react";
import PropTypes from "prop-types";
import NoData from "./NoData";
import HouseCard from "../components/HouseCard";

class HouseList extends Component {
  static propTypes = {
    houses: PropTypes.array.isRequired
  };

  render() {
    const { houses } = this.props;

    if (houses.length === 0) return <NoData />;

    return (
      <div className="places">
           {houses.map(house => (
          <HouseCard key={house.id} house={house} />
        ))}
      </div>
    );
  }
}

export default HouseList;
