import React from "react";
import PropTypes from "prop-types";
import { List } from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons-react";

const FeatureList = ({ place }) => {
  const features = {
    wifi: "Wifi",
    pool: "Pool",
    petFriendly: "Pet-Friendly",
    hotTub: "Hot Tub",
    patio: "Patio",
    bbqGrill: "BBQ Grill",
    firePit: "Fire Pit",
    poolTable: "Pool Table",
    fireplace: "Fire Place",
    mediaRoom: "Media Room",
    tv: "TV"
  };

  return (
    <List sx={{ listStyleType: 'none' }}>
      {Object.keys(features).map(feature => (
        <div key={feature}>
          {
            place[feature] ?
              <List.Item key={feature}>
                {place[feature] ? <IconCheck /> : <IconX />}
                {features[feature]}
              </List.Item> : null
          }
        </div>
      ))}
    </List>
  );
};

FeatureList.propTypes = {
  place: PropTypes.object.isRequired
};

export default FeatureList;
