import React, { Component } from "react";
import PropTypes from "prop-types";
import debounce from "lodash.debounce";

class JobSearchCriteria extends Component {
  static propTypes = {
    criteria: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
  };

  notifyOnChange = newCriteria => {
    this.props.onChange(newCriteria);
  };

  clear = () => {
    this.notifyOnChange({});
  };

  handleMatchingChange = debounce(event => {
    const { value } = event.target;

    let newValue;
    if (value) {
      newValue = value;
    } else {
      newValue = undefined;
    }

    const newCriteria = {
      ...this.props.criteria,
      matching: newValue
    };

    this.notifyOnChange(newCriteria);
  }, 500);


  handleCheckboxChange = (name, value) => {
    let newValue;
    switch (value) {
      case 1:
        newValue = true;
        break;
      case 2:
        newValue = false;
        break;
      default:
        newValue = undefined;
    }

    const newCriteria = {
      ...this.props.criteria,
      [name]: newValue
    };

    this.notifyOnChange(newCriteria);
  };

  checkboxValue = feature => {
    switch (this.props.criteria[feature]) {
      case true:
        return 1;
      case false:
        return 2;
      default:
        return 0;
    }
  };

  render() {
    const { criteria } = this.props;

    return (
      <div className="search-criteria">
        <h4>Text Search</h4>
        <div className="input-group">
          <div className="input-group-prepend">
            <div className="input-group-text">
              <i className="fas fa-search" />
            </div>
          </div>
          <input
            type="search"
            name="matching"
            placeholder="Search..."
            defaultValue={criteria.matching}
            key={criteria.matching}
            autoFocus
            onChange={e => {
              e.persist();
              this.handleMatchingChange(e);
            }}
          />
        </div>

        <div className="clear">
          <button onClick={this.clear}>Clear All</button>
        </div>
      </div>
    );
  }
}

export default JobSearchCriteria;
