import React from "react";
import { Button, Title, TextInput, Grid, Group } from "@mantine/core";

const PropertyType = ({ nextStep, prevStep, form }) => {
  // for continue event listener

  const isFormValid = (event) => {
    return form.values.location.length > 0;
  };

  return (
    <Grid>
      <Grid.Col>
        <Title order={2}>Basic Information</Title>
        <TextInput
          mt="sm"
          label="Location of the Place"
          placeholder="location "
          {...form.getInputProps("location")}
        />
        <Group position="right" mt="xl">
          <Button variant="contained" onClick={prevStep}>
            Previous
          </Button>
          <Button onClick={nextStep} disabled={!isFormValid()}>
            Next
          </Button>
        </Group>
      </Grid.Col>
    </Grid>
  );
};

export default PropertyType;
