import React, { Component } from "react";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import Loading from "./Loading";
import { GET_CURRENT_USER_QUERY } from "./CurrentUser";
import {
  TextInput,
  PasswordInput,
  Paper,
  Title,
  Text,
  Container,
  Button,
} from '@mantine/core';

const SIGNIN_MUTATION = gql`
  mutation SignIn($email: String!, $password: String!) {
    signin(email: $email, password: $password) {
      emailToken
    }
  }
`;

class SigninForm extends Component {
  state = {
    email: "",
    password: ""
  };

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  isFormValid = event => {
    return this.state.email.length > 0 && this.state.password.length > 0;
  };

  handleCompleted = data => {
    // localStorage.setItem("auth-token", data.signin.token);
    this.props.history.push(`/confirm-account/${data.signin.emailToken}`);
  };

  handleUpdate = (cache, { data }) => {
    cache.writeQuery({
      query: GET_CURRENT_USER_QUERY,
      data: { me: data.signin.user }
    });
  };

  onError = () => {
    console.log(this.state)
  }


  render() {
    return (
      <Mutation
        mutation={SIGNIN_MUTATION}
        variables={this.state}
        onError={this.onError}
        onCompleted={this.handleCompleted}
        update={this.handleUpdate}>
        {(signin, { error, loading }) => {
          if (loading) return <Loading />;
          return (
            <form
              className="signin"
              onSubmit={e => {
                e.preventDefault();
                signin();
              }}>

              <Container size={420} my={40}>
                <Title
                  align="center"
                  sx={(theme) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 900 })}
                >
                  Welcome back!
                </Title>
                <Text color="dimmed" size="sm" align="center" mt={5}>
                  Do not have an account yet?{' '}
                  <Link to="/sign-up" className="anchor">Create Account</Link>
                </Text>
                <Paper withBorder shadow="md" p={30} mt={30} radius="md">
                  <TextInput
                    required
                    label="Email"
                    placeholder="hello@mantine.dev"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    error={error ? "Email of password is invalid" : ""}
                    radius="md"
                  />
                  <PasswordInput
                    required
                    label="Password"
                    placeholder="Your password"
                    name="password"
                    value={this.state.password}
                    onChange={this.handleChange}
                    error={error ? true : false}
                    radius="md"
                  />
                   <Button fullWidth mt="xl" type="submit" disabled={loading || !this.isFormValid()}>
                    Signin
                  </Button>
                </Paper>
              </Container>

              {/* <Container component="main" maxWidth="xs">
                <Box
                  sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                  </Avatar>
                  <Typography component="h1" variant="h5">
                    Sign in
                  </Typography>
                  <TextField
                    variant="standard"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={this.state.email}
                    onChange={this.handleChange}
                    error={error ? true : false}
                    helperText={error ? error.message.replace("GraphQL error: ", "") : ""}
                  />
                  <TextField
                    variant="standard"
                    margin="normal"
                    type="password"
                    required
                    fullWidth
                    id="password"
                    label="Password"
                    name="password"
                    autoComplete="password"
                    autoFocus
                    value={this.state.password}
                    onChange={this.handleChange}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Sign In
                  </Button>

                </Box>
              </Container> */}
            </form>
          );
        }}
      </Mutation>
    );
  }
}

export default withRouter(SigninForm);
